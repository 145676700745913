import { Injectable,  } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root'})
export class HelperService {

    private URL = `${environment.api}`;
    userToken: any = localStorage.getItem('pipoohUser');

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  authFormData(formData) {
    this.userToken = localStorage.getItem('pipoohUser');
    formData.token = this.userToken;
    return formData;
  }
  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });
  }

  freteCorreios(formData) {
    // console.log("formData Correio: ",formData);
    return this.postData(`${this.URL}helper/rh/calculo_frete_completo`, this.authFormData(formData));
  }

  divulgacaoEmail(formData) {
    return this.postData(`${this.URL}helper/rh/divulgacao/email`, this.authFormData(formData));
  }
}
