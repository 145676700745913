import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';
import { RhService } from '../../../admin/_services/rh.service';
import { Router } from '@angular/router';
declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {

    infoUsuario: any = [];
    cor: any;
    constructor(
        private _authService: AuthService, 
        private _rhService: RhService, 
        private _router: Router) {

    }
    ngOnInit() {
        this.getInfoUsuario();
    }
    getInfoUsuario(){
        let data = localStorage.getItem('pipoohUser'); 
        this._authService.getToken({"token":data}).toPromise().then(data =>
            {
                this.infoUsuario = data;
                this.infoUsuario = this.infoUsuario.payload[0];
                if(this.infoUsuario.fkRh){
                    this._rhService.buscarRh({"fkRh": this.infoUsuario.fkRh}).then(data => {
                        this.cor = data.payload[0].corBackground;
                    })
                }
                console.log("infoUsuario", this.infoUsuario)
            }).catch(e => { console.log(e); });
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }

}