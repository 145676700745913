import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = [
    {
        'path': '',
        'component': AdminComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'admin',
                'loadChildren': '.\/pages\/dashboard\/dashboard.module#DashboardModule',
            },
            {
                'path': 'presentear',
                'loadChildren': '.\/pages\/presentear\/presentear.module#PresentearModule',
            },
            {
                'path': 'eventos',
                'loadChildren': '.\/pages\/eventos\/eventos.module#EventosModule',
            },
            {
                'path': 'produto',
                'loadChildren': '.\/pages\/produto\/produto.module#ProdutoModule',
            },
            {
                'path': 'beneficiarios',
                'loadChildren': '.\/pages\/beneficiarios\/beneficiarios.module#BeneficiariosModule',
            },
            {
                'path': 'gestores',
                'loadChildren': '.\/pages\/gestores\/gestores.module#GestoresModule',
            },
            {
                'path': 'perfil',
                'loadChildren': '.\/pages\/perfil\/perfil.module#PerfilModule',
            },
            {
                'path': 'vouchers',
                'loadChildren': '.\/pages\/vouchers\/vouchers.module#VouchersModule',
            },
            {
                'path': 'resgate',
                'loadChildren': '.\/pages\/resgate\/resgate.module#ResgateModule',
            },
            {
                'path': 'resgate-lista',
                'loadChildren': '.\/pages\/resgate-lista\/resgate-lista.module#ResgateListaModule',
            },
            {
                'path': '',
                'redirectTo': 'admin',
                'pathMatch': 'full',
            },
        ],
    },
    {
        'path': '**',
        'redirectTo': 'admin',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }