import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';
import { RhService } from '../../../admin/_services/rh.service';
import { environment } from '../../../../environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {

    public URL = "https://img.pipooh.com.br/";
    infoEmpresa: any = [];
    imagem: any;
    cor: any;
    imagemFundo: any;
    constructor(
        private _authService: AuthService, 
        private _rhService: RhService, 
        private _router: Router) {

    }
    ngOnInit() {
        this.getInfoEmpresa();
    }
    getInfoEmpresa(){
        let data = localStorage.getItem('pipoohUser'); 
        this._authService.getToken({"token":data}).toPromise().then(data =>
            {
                this.infoEmpresa = data;
                this.infoEmpresa = this.infoEmpresa.payload[0];
                console.log("infoEmpresa", this.infoEmpresa.fkRh)
                // this.infoEmpresa.color = "red;";
                if(this.infoEmpresa.fkRh){
                    this._rhService.buscarRh({"fkRh": this.infoEmpresa.fkRh}).then(data => {
                        this.cor = data.payload[0].corBackground;
                        this.imagemFundo = this.URL + data.payload[0].logo;
                    })
                }
                // if(this.infoEmpresa == false || this.infoEmpresa == null) { this._authService.logout();  this._router.navigate([`/logout`]); }
                this.imagem = this.URL + this.infoEmpresa.logo;
            }).catch(e => { console.log(e); });
    }
    ngAfterViewInit() {

        mLayout.initHeader();

    }

}